import { getFileType } from "@common/services/filesService"

angular
    .module('DigiLean')
    .directive("taskAttachment", ['$filter', '$uibModal',
        function ($filter, $uibModal) {
            return {
                templateUrl: 'taskattachment.html',
                restrict: 'E',
                scope: {
                    'file': '<',
                },
                link: function (scope, elem, attrs) {
                    
  
                    scope.$watch('file', function (file) {
                        // apply
                        if (!file) return;
                        if (file.validFileUrl) return;
                        var fileType = getFileType$filter(file.fileExtension)
                        if (fileType) {
                            file.fileType = fileType.type
                        } 
                    });
                }
            }
        }]);
