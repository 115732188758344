// import common web/teams modules
import "@common/thirdparty"


import '@common/thirdparty/orgchart/orgchart.js'; // For testing in strategymap => Remove if not going to be used
import 'nestable';

import 'bootstrap-select';
import 'bootstrap-select/dist/css/bootstrap-select.css';

import 'angular-cron-gen'
import 'metismenu';

import 'ng-file-upload';
import '@common/thirdparty/draganddrop/draganddrop.min';
import '@common/thirdparty/inspinia/inspinia';
import 'angular-file-saver';
import 'blob-tmp';

import 'angular-ui-tree';

import './app/app';
import './app/config';

import "@common/digileanSharedFiles"

// services

import '@common/shared/auth/authService';
import '@common/shared/auth/authInterceptorService';

import '@common/shared/domainService';
import '@common/shared/hmsService';
import '@common/shared/themeService';
import '@common/shared/layout/clock/clock';
import '@common/shared/layout/fullscreen/fullscreen';

import '@common/shared/layout/version/versionChecker';
import '@common/shared/evaluationService';
import '@common/shared/dataValueCenter';
import '@common/shared/activityCenter';


// directives

import '@common/components/admin/data/dropzoneLocal'
import '@common/components/hours/hoursService';
import '@common/components/hours/hub/hoursHubController';
import '@common/components/hours/invoiceReport/invoiceReport';
import '@common/components/hours/hoursRegistration';

// Strategy
import '@common/components/strategy/strategyGoalService';
import '@common/components/strategy/strategyController';
import '@common/components/strategy/strategyMap/strategyMap';
import '@common/components/strategy/strategyMap/strategyMapChart/strategyMapChart';
import '@common/components/strategy/strategyMap/cards/strategyCardPopup';
import '@common/components/strategy/strategyMap/cards/strategyGoalCard';
import '@common/components/strategy/strategyKPI/strategyBowlerChartController';
import '@common/components/strategy/strategyKPI/strategyBowlerMonthlyActual';
import '@common/components/strategy/strategyKPI/strategyBowlerMonthlyPlan';
import '@common/components/strategy/strategyKPI/strategyBowlerTitle';
import '@common/components/strategy/strategyKPI/strategyKPIConfigurationController';
import '@common/components/strategy/goalDetailsController';
import '@common/components/strategy/goalCreate/goalCreate';
import '@common/components/strategy/goalCreate/goalCreatePopup';
import '@common/components/strategy/goalEdit/goalEdit';
import '@common/components/strategy/goalEdit/goalEditPopup';
import '@common/components/strategy/goalKanban/goalKanban';
import '@common/components/strategy/goalKanban/goalKanbanCard'
import '@common/components/strategy/goalSelector/goalSelectorController';
import '@common/components/strategy/teamGoalsTable/teamGoalsTable';
import '@common/components/strategy/teamGoalsTable/goalKpiTable/goalKpiTable';
import '@common/components/strategy/strategyboard/strategyBoard';
import '@common/components/strategy/strategyboard/breakthroughNewController';
import '@common/components/strategy/strategyboard/assetCell/assetCell';
import '@common/components/strategy/strategyboard/strategyDescriptionCell/strategyDescriptionCell.js';
import '@common/components/strategy/strategyboard/strategyNameCell/strategyNameCell';
import '@common/components/strategy/strategyboard/strategyEndDateCell/strategyEndDateCell';
import '@common/components/strategy/strategyboard/strategyStartDateCell/strategyStartDateCell';
import '@common/components/strategy/strategyboard/strategySubGoalCell/strategySubGoalCell';
import '@common/components/strategy/links/strategyLinks';
import '@common/components/strategy/attachments/strategyAttachments';
import '@common/components/strategy/strategyGoalResources/strategyGoalResourceLinks';
import '@common/components/strategy/strategyTaskList/strategyTaskListResult';
import '@common/components/strategy/strategyTaskList/strategyTaskList';


import '@common/components/A3/a3ActionList/a3ActionList';
import '@common/components/A3/a3ActionList/a3SmartActionList';
import '@common/components/A3/comments/a3Comments';
import '@common/components/A3/cards/a3Card';
import '@common/components/A3/a3Table/a3TableService';
import '@common/components/A3/a3Table/a3Table';
import '@common/components/A3/a3Table/a3selector/a3SelectorController';
import '@common/components/A3/a3Table/taskList/a3TaskListModalController';
import '@common/components/A3/a3Table/templates/a3LinkToPreviewCell/a3LinkToPreviewCell';
import '@common/components/A3/a3Table/templates/a3GoalCell/a3GoalCell';
import '@common/components/A3/a3Table/templates/a3StatusCell/a3StatusCell';
import '@common/components/A3/a3Table/templates/a3OwnerCell/a3OwnerCell';
import '@common/components/A3/a3Table/templates/a3TasksCell/a3TasksCell';
import '@common/components/A3/a3Table/templates/a3TitleCell/a3TitleCell';
import '@common/components/A3/a3Table/templates/a3ProgressTasksCell/a3ProgressTasksCell';
import '@common/components/A3/a3Table/templates/a3EndDateCell/a3EndDateCell';
import '@common/components/A3/a3Table/templates/a3ParticipantsCell/a3ParticipantModalController';
import '@common/components/A3/a3Table/templates/a3ParticipantsCell/a3ParticipantsCell';
import '@common/components/A3/a3milestones/a3milestones';
import '@common/components/A3/preview/a3info/a3infoController';
import '@common/components/A3/preview/cost/costInfoController';
import '@common/components/A3/preview/a3step/a3stepController';
import '@common/components/A3/preview/a3stepActionPlan/a3stepActionPlanController';
import '@common/components/A3/templates/layout/a3info/a3infoTemplateController';
import '@common/components/A3/templates/layout/cost/costInfoTemplateController';
import '@common/components/A3/templates/layout/a3step/a3stepTemplateController';
import '@common/components/A3/templates/layout/a3stepActionPlan/a3stepActionPlanTemplateController';
import '@common/components/A3/templates/store/a3templateStore';
import '@common/components/A3/reports/monthlyrealized/initiativesmonthlyrealized';

import '@common/components/A3/reports/monthlyrealized/timeinitiativesmonthlyrealized';
import '@common/components/A3/reports/aggregatedrealized/initiativesaggregatedtimerealized';
import '@common/components/A3/reports/aggregatedrealized/initiativesaggregatedrealized';
import '@common/components/actionlist/a3/actionlistA3';
import '@common/components/actionlist/a3tasks/actionlistA3tasks';
import '@common/components/actionlist/actionlist';
import '@common/components/actionlist/boards/actionlistBoards';
import '@common/components/actionlist/boardtasks/actionlistBoardtasks';
import '@common/components/actionlist/deviation/actionlistDeviation';
import '@common/components/actionlist/deviationtasks/actionlistDeviationtasks';
import '@common/components/actionlist/improvement/actionlistImprovement';
import '@common/components/actionlist/improvementtasks/actionlistImprovementtasks';
import '@common/components/admin/data/datasourceTrend/datasourceTrendReport';
import '@common/components/admin/data/registrationtarget/registrationtarget';

import '@common/components/board/template/newFlexiboardTemplateController';

import '@common/components/dashboard/designer/appstore/greencross/greencross-date';
import '@common/components/dashboard/designer/appstore/greencross/greencrossController';
import '@common/components/dashboard/greencross/colorlabelConfigurationController';
import '@common/components/dashboard/designer/appstore/greencross/greencrossStatusModalController';
import '@common/components/dashboard/designer/appstore/dailyKPI/daily-kpi-date';
import '@common/components/dashboard/designer/appstore/dailyKPI/fiveSController';
import '@common/components/dashboard/designer/appstore/dailyKPIDynamic/dailyKpiDynamic';
import '@common/components/dashboard/designer/appstore/dailyKPIK/kLetter';
import '@common/components/dashboard/designer/appstore/dailyKPIA/aLetter';
import '@common/components/dashboard/designer/appstore/dailyKPID/dLetter';
import '@common/components/dashboard/designer/appstore/dailyKPIL/lLetter';
import '@common/components/dashboard/designer/appstore/dailyKPIP/pLetter';
import '@common/components/dashboard/designer/appstore/dailyKPIQ/qLetter';
import '@common/components/dashboard/designer/appstore/dailyKPIC/cLetter';
import '@common/components/dashboard/designer/appstore/dailyKPII/iLetter';
import '@common/components/dashboard/designer/appstore/livetrend/livetrendController';
import '@common/components/dashboard/designer/appstore/multichart/multichartcontroller';
import '@common/components/dashboard/designer/appstore/targetgraph/targetgraph';
import '@common/components/dashboard/designer/appstore/trendbarchart/trendbarchart';
import '@common/components/dashboard/designer/appstore/minmaxchart/minmaxchart';
import '@common/components/dashboard/designer/appstore/stackedHorizontalBarChart/stackedHorizontalBarChart';
import '@common/components/dashboard/designer/appstore/splitPackedBubbleChart/splitPackedBubbleChart';
import '@common/components/dashboard/designer/appstore/pareto/paretochart';
import '@common/components/dashboard/designer/appstore/piechart/piechartcontroller';
import '@common/components/dashboard/designer/appstore/strategyMapChartApp/strategyMapChartAppController';
import '@common/components/dashboard/radarchart/radarchart';
import '@common/components/dashboard/radarchart/radarchartRegistrationController';
import '@common/components/dashboard/designer/appstore/registration/registration';
import '@common/components/dashboard/designer/appstore/registration/registrationDialogController';
import '@common/components/dashboard/designer/appstore/register/registerController';
import '@common/components/dashboard/designer/appstore/singlevalue/singlevalueController';
import '@common/components/dashboard/designer/appstore/singlevalueregistration/singlevalueregistrationController';
import '@common/components/dashboard/designer/appstore/singlevaluetarget/singlevaluetarget';
import '@common/components/dashboard/designer/appstore/teamtasks/teamtasks';
import '@common/components/dashboard/designer/appstore/timeboard/timeboard';
import '@common/components/dashboard/designer/appstore/projectboard/projectboardApp';
import '@common/components/dashboard/designer/appstore/performanceboard/performanceboard';
import '@common/components/dashboard/designer/appstore/performanceboard/configuration/performanceboardConfig';
import '@common/components/dashboard/designer/appstore/performanceboard/configuration/performanceboardConfigController';
import '@common/components/dashboard/designer/appstore/a3table/a3tableApp';
import '@common/components/dashboard/designer/appstore/smarttable/smarttableApp';
import '@common/components/dashboard/designer/appstore/kanbanboard/kanbanBoard';
import '@common/components/dashboard/designer/appstore/weeklyboard/weeklyboard';
import '@common/components/dashboard/designer/appstore/weeklyboard/weekSelector';
import '@common/components/dashboard/designer/appstore/texteditor/texteditorController';
import '@common/components/dashboard/designer/appstore/usersonline/usersonlineController';

import '@common/components/dashboard/designer/appstore/datasourceValuesList/datasourceValuesList';
import '@common/components/dashboard/designer/appstore/smileyApp/smileyApp'


// IMPROVEMENT
import '@common/components/improvement/wheelImprovement/wheelImprovementController';
import '@common/components/improvement/improvementController';
import '@common/components/improvement/mysuggestions/mysuggestionsController';
import '@common/components/improvement/improvementreports/teammemberimprovement';

import '@common/components/improvement/improvementstatus.js';
import '@common/components/improvement/newFeedbackModalController';

import '@common/components/learning/learningController';
import '@common/components/learning/learningService';
import '@common/components/learning/learningPublicService';
import '@common/components/learning/viewer/learningViewerController';
import '@common/components/learning/viewer/learningSummaryViewer';
import '@common/components/learning/pdf/learningPagePdfExport';
import '@common/components/learning/comments/learningPageComments';
import '@common/components/learning/comments/learningPageCommentController'; // check location of this file
import '@common/components/learning/evaluation/learningPageEvaluation';
import '@common/components/learning/evaluation/learningPageEvaluationStatus';
import '@common/components/learning/favorites/myFavoritePages';
import '@common/components/tasks/team/teammember';
import '@common/components/tasks/info/taskInfo';
import '@common/components/tasks/taskstatus/personalTaskStatus';
import '@common/components/tasks/taskstatus/animationTaskStatus';
import '@common/components/tasks/subtasks/newSubTaskList';
import '@common/components/tasks/subtasks/subTaskList';
import '@common/components/tasks/taskList/taskListModalController';
import '@common/components/tasks/taskList/projectTaskList';

import '@common/shared/layout/inTrialInfo/inTrialInfo';
import '@common/shared/layout/navnewtask/navnewtask';
import '@common/shared/layout/navnewsuggestion/navnewsuggestion';
import '@common/shared/layout/navnewa3/navnewa3';
import '@common/shared/layout/navnewbubble/navnewbubble';

import '@common/shared/layout/favorites/favoritesController';
import '@common/shared/modal/draggable-modal';

import '@common/shared/contenteditable/contentEditable';
import '@common/shared/htmlTextArea/htmlTextArea';
import '@common/shared/comments/topLevelComment';
import '@common/shared/comments/replyableComment';
import '@common/shared/comments/commentEditor';
import '@common/shared/messages/message';
import '@common/shared/messages/replyableMessage';
import '@common/shared/messages/simpleReply';
import '@common/shared/messages/messageRepliesDialog';
import '@common/shared/editors/froalaCustom';
import '@common/shared/editors/froalaDiagram';
import '@common/shared/editors/froalaEditor';
import '@common/shared/editors/froalaView';
import '@common/shared/errorHandling/onErrorSrc';
import '@common/animations/heart/heartAnimation';
import '@common/shared/logs/logMessageViewer';
import '@common/shared/zoom/zoomSelector';


import './app/directives';
import './app/filters';


// controllers

import './app/mainController';
import './app/navigationController';


import '@common/components/A3/templates/a3templateService';
import '@common/components/A3/templates/preview/a3TemplatePreview';
import '@common/components/A3/a3Controller';
import '@common/components/A3/a3StepDescription/a3StepDescriptionModalController';
import '@common/components/A3/designer/a3designerController';
import '@common/components/A3/newA3/newA3ModalController';
import '@common/components/A3/a3wizardController';
import '@common/components/A3/preview/a3previewController';
import '@common/components/A3/slideshow/a3slideshowController';
import '@common/components/A3/templates/a3templatesController';
import '@common/components/A3/templates/layout/a3templateLayoutController';
import '@common/components/A3/reports/a3reportsController';
import '@common/components/A3/reports/benefitsRealizationController';
import '@common/components/A3/reports/timeBenefitsRealizationController';
import '@common/components/A3/links/a3Links';
import '@common/components/actionlist/actionlistService';
import '@common/components/admin/area/area';

import '@common/components/admin/apiclients/apiClients'
import '@common/components/admin/apiclients/apiClients.css'
import '@common/components/admin/apiclients/newApiClientController'
import '@common/components/admin/apiclients/editApiClientController'


import '@common/components/admin/contactlist/contactlist';
import '@common/components/admin/assets/assetscontroller';
import '@common/components/admin/assets/assetEdit';
import '@common/components/admin/organization/organizationAdminController';
import '@common/components/admin/organization/organizationSettings';
import '@common/components/admin/organization/accountSetup/accountSetupController';
import '@common/components/admin/organization/messagecenter/mailsender';
import '@common/components/admin/organization/messagecenter/messageSender';
import '@common/components/admin/organization/messagecenter/pushNotificationSender';
import '@common/components/dashboard/smartlogic/cellCloneConfigController';
import '@common/components/admin/improvement/improvementAdminController';
import '@common/components/admin/a3/a3AdminController';
import '@common/components/admin/changepassword/changePasswordModalController';
import '@common/components/admin/customers/deployCustomerDialogController';
import '@common/components/admin/customers/disableCustomerDialogController';
import '@common/components/admin/customers/supportUserDialogController';
import '@common/components/admin/customers/customerOrderDialogController';
import '@common/components/admin/customers/allowedModulesDialogController';
import '@common/components/admin/customers/customers.css';
import '@common/components/admin/customers/customersController';
import '@common/components/admin/customers/payingCustomersController';
import '@common/components/admin/customers/trialsController';
import '@common/components/admin/customers/invoicing';
import '@common/components/admin/customers/invoices';
import '@common/components/admin/integrations/connectors/newConnectorController'

import '@common/components/admin/data/lists/datalistController';
import '@common/components/admin/data/lists/datalistsController';
import '@common/components/admin/data/lists/newDataListController';
import '@common/components/admin/data/lists/editDataListController';
import '@common/components/admin/data/excelImportController';
import '@common/components/admin/data/excel/singleDataSourceImportController';
import '@common/components/admin/data/excel/multipleDataSourcesImportController';
import '@common/components/admin/data/newdatasource/newDataSourceController';


import '@common/components/admin/data/dataAdminController';
import '@common/components/admin/data/dataSourceController';

import '@common/components/admin/deviations/deviationTypeDetails/deviationTypeDetailsController';
import '@common/components/admin/deviations/newDeviationType/newDeviationTypeController';
import '@common/components/admin/deviations/editDeviationType/editDeviationTypeController';
import '@common/components/admin/deviations/editDeviationTypePermission/editDeviationTypePermissionController';
import '@common/components/admin/deviations/deviationTypeAdminController';
import '@common/components/admin/deviations/deviationTypeCategories/deviationTypeCategories';
import '@common/components/admin/deviations/deviationTypeCauses/deviationTypeCauses';
import '@common/components/admin/deviations/deviationTypeConsequences/deviationTypeConsequences';
import '@common/components/admin/deviations/deviationTypeSetting/deviationTypeSetting';
import '@common/components/admin/deviations/deviationCustomFieldAdmin/deviationCustomFieldAdminController';
import '@common/components/admin/deviations/categoryAdminController';
import '@common/components/admin/deviations/causeAdminController';
import '@common/components/admin/deviations/consequenceAdminController';
import '@common/components/admin/learning/learningAdminController';
import '@common/components/admin/learning/newLearningMaterial/newLearningMaterialController';
import '@common/components/admin/learning/editLearningMaterial/editLearningMaterialController';
import '@common/components/admin/learning/stats/learningMaterialStatsController';
import '@common/components/admin/learning/designer/learningDesignerController';
import '@common/components/admin/learning/designer/learningSummary';
import '@common/components/admin/learning/designer/learningFrontPage';
import '@common/components/admin/learning/designer/learningPage';
import '@common/components/admin/learning/designer/sections/textSection';
import '@common/components/admin/learning/designer/sections/imageSection';
import '@common/components/admin/learning/designer/sections/summarySection';
import '@common/components/admin/learning/designer/sections/diagramSection';
import '@common/components/admin/learning/designer/sections/videoSection';
import '@common/components/admin/learning/designer/sections/fileSection';
import '@common/components/admin/data/lists/customerAdminController';
import '@common/components/admin/projects/projectRoleAdminController';
import '@common/components/admin/projects/projectAdminController';
import '@common/components/admin/projects/projectListAdminController';
import '@common/components/admin/projects/projectPortfolioAdminController';
import '@common/components/admin/organization/signupService';
import '@common/components/admin/users/userAccessController'
import '@common/components/admin/users/userscontroller';
import '@common/components/admin/users/userEdit';
import '@common/components/admin/users/userListResult';


import '@common/components/dashboard/designer/appstore/actionList/actionListNewTaskController';
import '@common/components/dashboard/designer/appstore/actionList/actionListEditTaskController';

import '@common/components/board/newBoard/boardtypes/dashboard/dashboardPreview';

import '@common/components/board/boardActionController';

import '@common/components/admin/boards/flexiboardContainerAdminController';
import '@common/components/admin/boards/flexiboardAdminController';
import '@common/components/admin/boards/flexiboardTemplateAdmin';

import '@common/components/common/confirmationDialog/confirmationDialogController';
import '@common/components/common/members/membersSelector';
import '@common/components/common/access/boardAccessChecker';
import '@common/components/common/numericInput/numericInput';
import '@common/components/dashboard/activitycount/activitycountController';
import '@common/components/dashboard/customerfeedback/changestatusController';
import '@common/components/dashboard/customerfeedback/customerfeedbackController';
import '@common/components/dashboard/customermonitoring/customerMonitoringController';
import '@common/components/dashboard/customermonitoring/notifications/notificationsOverview';
import '@common/components/dashboard/customermonitoring/licenses/licensesChanges';
import '@common/components/dashboard/dailyfeed/dailyfeedController';
import '@common/components/dashboard/dailyfeed/dailyfeedActivitySelector';
import '@common/components/dashboard/designer/dashboardDesignerController';

import '@common/components/dashboard/designer/appstore/multichart/graphConfigurationController';
import '@common/components/dashboard/designer/appstore/myCompetenceBalls/myCompetenceBalls';
import '@common/components/dashboard/designer/appstore/myA3s/myA3sController';
import '@common/components/dashboard/designer/appstore/myprojects/myprojectsController';
import '@common/components/dashboard/designer/appstore/teamtasks/teamTaskConfiguration';
import '@common/components/dashboard/designer/appstore/projectnextmilestone/projectNextMilestone';
import '@common/components/dashboard/systemactivity/systemactivityfeedController';
import '@common/services/admin/connectorAdminService'


import '@common/components/deviation/deviationSearchList/deviationList';
import '@common/components/deviation/deviationSearchList/deviationListFilter';
import '@common/components/deviation/deviationSearchList/deviationListResult';
import '@common/components/deviation/fullDeviation/fullDeviationController';
import '@common/components/deviation/mydeviations/mydeviationsController';
import '@common/components/deviation/ongoing/deviationColumn';
import '@common/components/deviation/ongoing/ongoingDeviation';
import '@common/components/deviation/ongoing/ongoingDeviation.css';
import '@common/components/deviation/ongoing/ongoingDeviationApp';
import '@common/components/deviation/ongoing/deviationCommonService';
import '@common/components/deviation/newDeviationController';
import '@common/components/deviation/newDeviation/newDeviationButton';
import '@common/components/deviation/deviationService';
import '@common/components/deviation/qualityService';
import '@common/components/deviation/newDeviationController';
import '@common/components/deviation/deviationCategories/deviationCategoriesSelector';
import '@common/components/deviation/projectStatus/deviationProjectStatusController';
import '@common/components/deviation/projectStatus/deviationProjectStatusPdfButton/deviationProjectStatusPdfButton';
import '@common/components/deviation/projectStatus/stackedDeviationHorizontalBarChart/stackedDeviationHorizontalBarChart.js';


import '@common/components/login/forgotPassword/forgotPasswordModalController';

import '@common/components/profile/myprofileController';
import '@common/components/profile/notifications/myNotifications';
import '@common/components/profile/notifications/myMessageNotifications';
import '@common/components/profile/profileSettings/profileSettingsController';
import '@common/components/profile/profileSettings/profileInfoSettings';
import '@common/components/profile/profileSettings/notificationSettings';
import '@common/components/profile/myRegisteredSuggestions/myRegisteredSuggestionsController';
import '@common/components/profile/myRegisteredIncidents/myRegisteredIncidentsController';
import '@common/components/profile/taskThisWeek/taskThisWeek';
import '@common/components/profile/myprofilephotoController';
import '@common/components/profile/profileImage';
import '@common/components/profile/inbox/inboxController';
import '@common/components/profile/inbox/inboxNotifications';
import '@common/components/profile/inbox/notificationMessages/taskAssigned/taskAssignedMessage';
import '@common/components/profile/inbox/notificationMessages/taskComment/taskCommentMessage';
import '@common/components/profile/inbox/notificationMessages/newDeviation/newDeviationMessage';
import '@common/components/profile/inbox/notificationMessages/deviationComment/deviationCommentMessage';
import '@common/components/profile/inbox/notificationMessages/improvementChangedStatus/improvementChangedStatusMessage';
import '@common/components/profile/inbox/notificationMessages/improvementComment/improvementCommentMessage';
import '@common/components/profile/inbox/notificationMessages/updatedResponsibleDeviation/updatedResponsibleDeviationMessage';
import '@common/components/profile/inbox/notificationMessages/updatedResponsibleSuggestion/updatedResponsibleSuggestionMessage';
import '@common/components/profile/inbox/notificationMessages/pageCommentAdded/pageCommentAddedMessage';
import '@common/components/profile/inbox/notificationMessages/licensesChanged/licensesChangedMessage';
import '@common/components/profile/inbox/notificationMessages/projectMessageAdded/projectMessageAdded';

// project - confirmed not common
import '@common/components/project/projectTarget/projectTarget';
import '@common/components/project/projectDelete/projectDeleteController';
import '@common/components/project/attachments/projectAttachments';
import '@common/components/project/projectboard/templates/linkCell/projectLinkCell';
import '@common/components/project/newProject/newProjectController';
import '@common/components/project/projectController';
import '@common/components/project/status/projectPercentPlanComplete';

import '@common/components/tasks/newTaskToActionListController';
import '@common/components/tasks/newtasktopersonaltasklistController';
import '@common/components/tasks/personaltaskListController';
import '@common/components/tasks/team/teamspaceController';
import '@common/components/tasks/team/teamKanban';
import '@common/components/tasks/team/teamKanbanCard';
import '@common/components/templates/templatesController';
import '@common/components/tasks/taskSearch/taskList';
import '@common/components/tasks/taskSearch/taskListFilter';
import '@common/components/tasks/taskSearch/taskListResult';

import '@common/components/improvement/status/feedbackstatus.js'
import '@common/components/dashboard/mydigiLeanfeedback/mydigileanfeedback.js'

import './css/layout.css';
import '../../node_modules/angular-ui-tree/dist/angular-ui-tree.min.css';

import '@common/thirdparty/datepicker3.css';
import '@common/thirdparty/angular-loading-bar.min.css';
import '@common/thirdparty/bootstrap/css/angular-bootstrap-lightbox.css';

import '../../node_modules/spinkit/css/spinkit.css';

import '@common/thirdparty/jquery.step.css';

import '@common/shared/comments/comments.css';
import '@common/shared/messages/messages.css';
import '@common/components/common/numericInput/numericInput.css';
import '@common/components/login/login.css';
import '@common/components/admin/data/lists/datalist.css';
import '@common/components/admin/learning/learning.css';
import '@common/components/A3/designer/a3designer.css';

import '@common/components/hours/hours.css';
import '@common/components/profile/profileSettings/profileSettings.css';

import '@common/components/tasks/tasks.css';
import '@common/components/tasks/taskSearch/taskListResult.css';
import '@common/components/actionlist/actionlist.css';

import '@common/components/board/newBoard/boardtypes/dashboard/dashboardPreview.css';

import '@common/components/deviation/deviationSearchList/deviationListResult.css';


import '@common/components/dashboard/designer/appstore/teamtasks/teamtasks.css';
import '@common/components/document/imagefileinfo/imagefile.css';
import '@common/components/strategy/strategy.css';
import '@common/components/strategy/goalCreate/goalCreate.css';
import '@common/components/strategy/goalEdit/goalEdit.css';
import '@common/components/strategy/strategyMap/strategyMap.css';
import '@common/components/strategy/strategyTaskList/strategyTaskListResult.css';
import '@common/components/dashboard/designer/dashboarddesigner.css';

import '@common/components/improvement/improvementActionList/improvementActionList.css';
import '@common/components/improvement/improvementList/improvementListResult.css';
import '@common/components/A3/a3.css';
import '@common/components/profile/profile.css';
import '@common/components/profile/inbox/inboxNotifications.css';
import '@common/components/profile/notifications/myNotifications.css';
import '@common/components/dashboard/dailyfeed/dailyfeed.css';
import '@common/components/templates/templates.css';
import '@common/components/deviation/deviation.css';
import '@common/components/A3/preview/a3previewDesigner.css';
import '@common/components/A3/templates/a3templates.css';
import '@common/components/A3/templates/preview/a3TemplatePreview.css';
import '@common/components/A3/templates/store/a3templateStore.css';
import '@common/components/dashboard/designer/appstore/appstore.css';

import '@common/components/dashboard/designer/appstore/calendar/calendar.css';
import '@common/components/dashboard/designer/appstore/kanbanboard/kanbanBoard.css';
import '@common/components/dashboard/designer/appstore/weeklyboard/weeklyboard.css';
import '@common/components/dashboard/designer/appstore/smartActionList/smartActionList.css';
import '@common/components/dashboard/designer/appstore/smileyApp/smileyApp.css';
import '@common/components/dashboard/designer/appstore/dailyKPI/fiveS.css';
import '@common/components/dashboard/designer/appstore/greencross/greencross.css';
import '@common/components/dashboard/designer/appstore/performanceboard/performanceboard.css';
import '@common/components/dashboard/designer/appstore/texteditor/texteditor.css';

import '@common/components/A3/a3Table/a3Table.css';
import '@common/shared/tasks/taskStatus.css';
import '@common/shared/layout/clock/clock.css';
import '@common/shared/layout/favorite.css';
import '@common/shared/zoom/zoom.css';

import '@common/animations/heart/heartAnimation.css';
import "@common/components/selectors/groupselector/groupSelector.css"

import '@common/components/admin/data/job.css';

import "@common/components/formatters/quartzCronDisplayer"

import "@common/components/diagram/DigileanDiagram.css"
import "@common/components/diagram/DigileanDiagramEditor"
import "@common/components/diagram/DigileanDiagram"
import "@common/components/diagram/DigileanDiagramApp"
import "@common/components/diagram/DiagramEditorFullscreen"
import "@common/components/diagram/DiagramTemplateSelector"
import "@common/services/user/userService"

import "@common/components/admin/integrations/integrations.css"
import "@common/components/admin/integrations/integrationsAdmin"
import "@common/components/admin/integrations/connectors/connectorAdmin"
import "@common/components/admin/integrations/jobs/newJobController"
import "@common/components/admin/integrations/jobs/jobAdmin"
import "@common/components/admin/integrations/jobs/editJobpartDialog"

import "@common/components/common/cron/cronInputGenerator"
import "@common/components/icons/DigileanChecked"
import "@common/components/icons/DigileanFailed"
import "@common/components/icons/DigileanWorking"
import "@common/components/icons/DigiLeanOkWithErrors"

import '@common/components/monitoring/JobRunStateViewer'
import '@common/components/monitoring/JobRunTimeViewer'
import '@common/components/monitoring/ConnectorStateViewer'

import '@common/components/monitoring/integrations/integrationsMonitor'
import '@common/components/monitoring/integrations/connectorMonitor'
import '@common/components/monitoring/integrations/jobLogs'
import '@common/components/monitoring/integrations/LoggMessageViewer'
import '@common/components/monitoring/monitoringAll'
import '@common/components/monitoring/monitoring.css'

import "@common/components/users/userBadge"
import "@common/components/formatters/scalableText"
import "@common/components/dashboard/designer/appstore/greencross/kpiMonthSelector"

import "@common/services/pageStateService"

import "@app/app/users/userComponent"

import "@common/components/admin/integrations/jobs/jobPartsAdmin"

// VUE components reg
import { defineCustomElement } from "vue"

import connectorMonitorTable from "@common/components/monitoring/integrations/ConnectorMonitorTable.vue"
customElements.define('connector-monitor-table', defineCustomElement(connectorMonitorTable))

// import jobRunsTable from "@common/components/monitoring/integrations/jobRunsTable.vue"
// customElements.define('job-runs', defineCustomElement(jobRunsTable))

import MonitorJobLog from "@common/components/monitoring/integrations/MonitorJobLog.vue"
const MonitorJobLogCe = defineCustomElement(MonitorJobLog)
customElements.define('monitor-job-log', MonitorJobLogCe)



import UserAzureLookup from "@common/components/users/UserAzureLookup.vue"
const UserAzureLookupCe = defineCustomElement(UserAzureLookup)
customElements.define('user-azure-lookup', UserAzureLookupCe)

import GroupAzureLookup from "@common/components/users/GroupAzureLookup.vue"
const GroupAzureLookupCe = defineCustomElement(GroupAzureLookup)
customElements.define('group-azure-lookup', GroupAzureLookupCe)

import strategyFlexibleBowlerChart from "@common/components/strategy/strategyKPI/strategyFlexibleBowlerChart.vue"
const strategyFlexibleBowlerChartCe = defineCustomElement(strategyFlexibleBowlerChart)
customElements.define('strategy-flexible-bowler-chart', strategyFlexibleBowlerChartCe)