interface FileExtension {
    type: string
    extension: string
}
const viewerFileExtensions: FileExtension[] = [
    { type: 'image', extension: '.jpg' },
    { type: 'image', extension: '.jpeg' },
    { type: 'image', extension: '.png' },
    { type: 'image', extension: '.jfif' },
    { type: 'pdf', extension: '.pdf' },
    { type: 'excel', extension: '.xlsx' },
    { type: 'excel', extension: '.xls' },
    { type: 'word', extension: '.doc' },
    { type: 'word', extension: '.docx' },
    { type: 'video', extension: '.mp4' },
    { type: 'powerpoint', extension: '.ppt' },
    { type: 'powerpoint', extension: '.pptx' },
]

export function getFileType(ext: string) {
    const fileExt = viewerFileExtensions.find(fe => fe.extension === ext.toLocaleLowerCase())
    return fileExt
}