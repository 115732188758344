import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"
import * as employeeService from "@common/services/employeeService"

angular
    .module('DigiLean')
    .directive("board", ['$state', '$timeout', '$translate', '$filter', '$uibModal', 'boardDrawService', 'navigationService', 'modalService', 'boardTaskService',
        function ($state, $timeout, $translate, $filter, $uibModal, boardDrawService, navigationService, modalService, boardTaskService) {
            return {
                templateUrl: 'board.html',
                restrict: 'E',
                scope: {
                    'boardData': '=',
                    'options': '<'
                    // 'mode': '@', // Monthly, daily, etc
                },
                link: function ($scope, element, attrs) {
                    $scope.assetName = "";
                    $scope.isWeeklyBoard = null;
                    $scope.isKamishibai = false;

                    $scope.isStar = false;
                    $scope.quickAccess = [];

                    $scope.hasBoardDesignerPrivileges = false;
                    $scope.showArchive = false;
                    $scope.showPostIts = false;
                    $scope.postIts = null;
                    $scope.weekNumberTranslation = $translate.instant("BOARD_WEEK_NO");
                    $scope.actionListOptions = {
                        targetHeight: null,
                        filter : null
                    };


                    // TimeZone test

                    function resizeOptionsTimeout() {
                        setTimeout(function () {
                            setHeightBasedOnOptions($scope.options);
                        }, 100);
                    }
            
                    function setHeightWindow(e) {
                        setHeight(window.innerHeight)
                    }
                    window.addEventListener("resize", setHeightWindow)

                    $scope.subscribe("EnterFullScreenMode", resizeOptionsTimeout)
                    $scope.subscribe("ExitFullScreenMode", resizeOptionsTimeout)

                    $scope.subscribe("PresentationViewStarted", function () {
                        setHeightBasedOnOptions($scope.options);
                    })
                    $scope.subscribe("PresentationViewStopped", function () {
                        setHeightBasedOnOptions($scope.options);
                    })
                    $scope.$watch("options", function (options) {
                        setHeightBasedOnOptions($scope.options);
                    })

                    $scope.$on("$destroy", function () {
                        window.removeEventListener("resize", setHeightWindow)
                    })
                    function setHeightBasedOnOptions(options) {
                        if (!options) {
                            setHeight();
                            return;
                        }
                        if (options.targetHeight) {
                            setHeight(options.targetHeight);
                        } else {
                            setHeight();
                        }
                    }

                    function setSettings(settings){
                        if (settings.isKamishibai) {
                            $scope.isKamishibai = settings.isKamishibai;
                        } 
                        if (settings.showPostIts) {
                            $scope.showPostIts = settings.showPostIts;
                            $scope.postIts = settings.PostIts;
                        }
                    }

                    $scope.$watch("boardData", function () {
                        if ($scope.boardData == null) return;
                        var board = $scope.boardData.board;
                        $scope.boardid = board.id;
                        var boardData = $scope.boardData;
                        $scope.title = boardData.board.name;
                        $scope.tags = board.tags;
                        if (board.boardType == "Weekly") {
                            $scope.isWeeklyBoard = true;
                        }
                        if (boardData.board.settings) {
                            $scope.settings = JSON.parse(boardData.board.settings);
                            setSettings($scope.settings);   
                        }
                      
                        var hasDeviation = navigationService().hasModule("DEVIATION");
                        boardData.access = {
                            hasDeviation: hasDeviation
                        };

                        // Set actionlist context
                        var viewType = "Board";
                        if (boardData.board.boardType && boardData.board.boardType === "Time") viewType = "TimeBoard";
                        $scope.actionListViewContext = {
                            view: viewType,
                            id: boardData.board.id
                        };


                        $scope.boardData = boardData;
                        $scope.assetid = boardData.board.assetId;
                        $scope.assetName = boardData.board.asset.name;

                        navigationService().isBoardAdmin($scope.boardid).then(function (isAdmin) {
                            $scope.hasBoardDesignerPrivileges = isAdmin;
                        });
                        boardService.canEdit($scope.boardid).then(function (canEdit) {
                            $scope.isViewerOnly = !canEdit;
                        });
                        employeeService.getUserSettings().then(function (userSettings) {
                            $scope.userSettings = userSettings;
                            var boards = $scope.userSettings.boards;
                            if (!boards) return;
                            var hasBoardActionListSetting = $filter('filter')(boards, { boardId: $scope.boardid }, true);
                            if (hasBoardActionListSetting.length > 0) {
                                $scope.showActionList = hasBoardActionListSetting[0].showActionList;
                            }
                        });
                        applyFilter(boardData.tags);
                        if ($scope.isWeeklyBoard) {
                            loadCurrentWeek();
                        }
                        $scope.archiveoptions = {
                            timePeriod: {
                                timeframe: "year",
                                useDefaultTimeframes: true,
                                timeframes: []
                            },
                            showArchive: false
                        };

                        checkFavorite();
                    });

                    $scope.subscribe("BoardSettingUpdated", function (boardsettinginfo) {
                        if (boardsettinginfo.boardId === $scope.boardid) {
                            $scope.settings = JSON.parse(boardsettinginfo.settings);
                            setSettings($scope.settings);
                        }
                    });

                    
                    function updateActionlistOptions(targetHeight, filter){
                        $scope.actionListOptions = {
                            targetHeight: targetHeight,
                            filter: filter
                        };  
                    }
                    // Height calculation
                    //$scope.actionListOptions = {};
                    function setHeight(targetHeight) {
                        if (!targetHeight) {
                            var boardContainer = $(".board-table-container");

                            var offset = boardContainer.offset();
                            var windowHeight = $(window).height();
                            var boardTargetHeight = windowHeight - offset.top - 50;
                            if (boardTargetHeight < 500) boardTargetHeight = 500;
                            boardContainer.css("height", boardTargetHeight);
                            // action list
                            targetHeight = windowHeight - offset.top;
                            if (targetHeight < 500) targetHeight = 500;
                            /*$scope.actionListOptions = {
                                targetHeight: targetHeight
                            };*/
                            updateActionlistOptions(targetHeight, $scope.actionListOptions.filter);
                            
                        } else {
                            var optionsHeight = 80;
                            var boardTableHeight = targetHeight - optionsHeight
                            $(".board-table-container").css("height", boardTableHeight);
                            /*$scope.actionListOptions = {
                                targetHeight: targetHeight
                            };*/
                            updateActionlistOptions(targetHeight, $scope.actionListOptions.filter);
                        }

                    }

                    $scope.isViewerOnly = null;

                    $scope.subscribe("BoardWeekChanged", function (weekChange) {
                        if ($scope.boardid == weekChange.boardid) {
                            $scope.week = weekChange.week;
                            $scope.weekYear = weekChange.weekYear;
                            $scope.weekNumber = weekChange.weekNumber;
                        }
                    });


                    var activeFilterButton = "btn-primary";
                    var inActiveFilterButton = "btn-default";

                    $scope.currentDateInWeek = null;
                    $scope.formats = ['dd.MM.yyyy', 'dd-MMMM-yyyy', 'yyyy/MM/dd', 'yyyy-MM-dd', 'shortDate']
                    $scope.format = $scope.formats[0];
                    $scope.weekpickerOpen = function () {
                        $scope.weekpicker.opened = true;
                    }
                    $scope.weekpickerDateChanged = function () {
                        if (!$scope.currentDateInWeek) return;
                        $scope.weekYear = moment($scope.currentDateInWeek).isoWeekYear();
                        $scope.weekNumber = moment($scope.currentDateInWeek).isoWeek();
                        loadCurrentWeek();
                    }
                    $scope.weekpicker = {
                        opened: false
                    };
                    $scope.dateOptions = timeService.globalDatePickerOptions();


                    $scope.isUnprocessedFilterActive = false;
                    $scope.tagFilterState = inActiveFilterButton;
                    $scope.showActionList = true;
                    // User settings
                    $scope.userSettings = null;

                    
                    $scope.predefinedPostIts = function () {

                        if (!$scope.settings) {
                            $scope.settings = {
                                IsWeeklyBoard: $scope.isWeeklyBoard
                            }
                        }
                        $scope.settings.showPostIts = $scope.showPostIts;
                        if($scope.showPostIts){
                            var modalInstance = $uibModal.open({ backdrop: 'static',
                                animation: true,
                                templateUrl: 'postitPredefinedTags.html',
                                controller: 'postitPredefinedTags',
                                resolve: {
                                    labels: function () {
                                        return $scope.settings.PostIts;
                                    }
                                }
                            });

                            modalInstance.result.then(function (result) {
                                $scope.settings.PostIts = result;
                                boardService.updateSettings($scope.boardid, $scope.settings).then(function () {
                                });

                            });
                        } else {
                            boardService.updateSettings($scope.boardid, $scope.settings).then(function () {
                            });
                        }
                    }

                    $scope.filterToggle = function(open) {
                        if (open && !$scope.isWeeklyBoard &&  $scope.tagFilterState == inActiveFilterButton) {
                            reloadTags();
                        }
                    }
                


                    setTimeout(function () {

                        $("#droparchive").sortable({
                            connectWith: ".boardcell",
                            placeholder: "portlet-placeholder ui-corner-all",
                            over: function (e, ui) {
                                // $("#actionList").addClass("actionList-active");
                            },
                            out: function (e, ui) {
                                //$("#actionList").removeClass("actionList-active");
                            },
                            receive: function (e, ui) {
                                // the li from the dom should contain data values
                                var seletedItem = ui.item[0];
                                // Read our suggestions id and text
                                var id = $(seletedItem).data("id");
                                var taskBeingMoved = $("#droparchive").find(".portlet")
                                $(taskBeingMoved).addClass("animated zoomOutDown");
                                setTimeout(function () {
                                    $(taskBeingMoved).remove();
                                }, 500);

                                boardTaskService().moveTaskToArchive(id).then(function (result) {
                                });

                            }
                        });

                    }, 20);

                    $scope.subscribe('UserSettingsChanged', function (userSettings) {
                        $scope.userSettings = userSettings;
                    });
                    $scope.toggleActionList = function () {
                        $scope.showActionList = !$scope.showActionList;


                        if (!$scope.userSettings) return;
                        if (!$scope.userSettings.boards) $scope.userSettings.boards = [];
                        var boards = $scope.userSettings.boards;
                        var hasBoardActionListSetting = $filter('filter')(boards, { boardId: $scope.boardid }, true);
                        if (hasBoardActionListSetting.length > 0) {
                            hasBoardActionListSetting[0].showActionList = $scope.showActionList;
                        } else { // Create setting for board
                            $scope.userSettings.boards.push({
                                boardId: $scope.boardid,
                                showActionList: $scope.showActionList
                            });
                        }
                        employeeService.updateUserSettings($scope.userSettings).then(function () {
                            $scope.publish("UserSettingsChanged", $scope.userSettings);
                        });
                    }



                    $scope.moveLastWeeksUncompletedTasks = function () {
                        var lastWeek = getPreviousWeek();
                        var week = timeService.getCurrentWeek(lastWeek.weekNumber, lastWeek.weekYear);
                        
                        boardService.moveUncompletedTasks($scope.boardid, week).then(function (data) {

                        });
                    }

                    $scope.toggleArchive = function () {
                        $scope.showArchive = !$scope.showArchive;
                        if ($scope.showArchive) {
                            $scope.showActionList = false;
                        } else {
                            $scope.showActionList = true;
                        }
                        $timeout(function () {
                            boardDrawService.refreshTableLayout();

                            $scope.archiveoptions = {
                                timePeriod: $scope.archiveoptions.timePeriod,
                                showArchive: $scope.showArchive
                            };

                        });


                    }

                    $scope.periodChangedHandler = function (timePeriod) {

                        if (!timePeriod.timeframe) return;
                        if ($scope.archiveoptions.timePeriod && $scope.archiveoptions.timePeriod.timeframe === timePeriod.timeframe) return;

                        $scope.archiveoptions = {
                            timePeriod: timePeriod,
                            showArchive: $scope.showArchive
                        };

                    }



                    $scope.assetUsers = [];
                    $scope.subscribe("BoardWeekLoaded", function (week) {
                        if (filteredUser) {
                            filterTagByUser(filteredUser.id);
                        }
                        if (filteredColor) {
                            filterTagByColor(filteredColor);
                        }
                    });


                    $scope.saveAsTemplate = function () {
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'newboardtemplate.html',
                            controller: 'newboardtemplateController',
                            resolve: {
                                board: function () {
                                    return $scope.boardData.board;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {

                        });
                    }

                    $scope.deleteBoard = function () {
                        if (!$scope.hasBoardDesignerPrivileges) return;
                        $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_DELETED_BOARD', 'ADMIN_DELETED_BOARD_DESCRIPTION']).then(function (translations) {
                            var modalOptions = {
                                closeButtonText: translations.COMMON_CANCEL,
                                actionButtonText: translations.ADMIN_DELETED_BOARD,
                                headerText: translations.COMMON_DELETE + ' ' + $scope.boardData.board.name + '?',
                                bodyText: translations.ADMIN_DELETED_BOARD_DESCRIPTION
                            };

                            modalService.showModal({}, modalOptions).then(function (result) {
                                boardService.deleteBoard($scope.boardid).then(function () {
                                    $state.go('flexiboards');
                                    // $scope.publish("SendBoardDeleted", $scope.boardData.board);
                                    $scope.publish("SendBoardDeleted", $scope.boardid);
                                });
                            });

                        });
                    }

                    $scope.filterOnStatusBlank = function () {
                        if ($scope.isUnprocessedFilterActive) {
                            resetFilter();
                            angular.element("div .portlet").show();
                            return;
                        }
                        angular.element("div .portlet").hide();
                        var tasksWithblankStatus = angular.element("div .status[data-status='blank']");
                        var tasks = angular.element(tasksWithblankStatus).closest("div .portlet");
                        $scope.tagFilterState = activeFilterButton;
                        $scope.isUnprocessedFilterActive = true;
                        tasks.show();
                        $(".rowcategory").css("height", 100);
                        var filter = {
                            status: 'blank'
                        };
                        updateActionlistOptions($scope.actionListOptions.targetHeight, filter);
                        
                    }


                    console.log("boardController loaded");
                    // Week settings
                    // if board.settings == week
                    $scope.weekYear = moment().isoWeekYear();
                    $scope.weekNumber = moment().isoWeek();
                    $scope.week = null;
                    $scope.archivePeriod = null;
                    function publishWeekChanged() {
                        var weekChange = {
                            boardid: $scope.boardid,
                            weekYear: $scope.weekYear,
                            weekNumber: $scope.weekNumber,
                            week: $scope.week
                        }
                        $scope.publish("BoardWeekChanged", weekChange);
                    }
                    function loadCurrentWeek() {
                        if ($scope.isWeeklyBoard) {
                            var week = timeService.getCurrentWeek($scope.weekNumber, $scope.weekYear);
                            $scope.week = week;
                        }
                        reloadTags();
                    }
                    $scope.nextWeek = function () {
                        var firstDayNextWeek = moment($scope.week.endDate).add(1, 'days');
                        $scope.weekYear = firstDayNextWeek.isoWeekYear();
                        $scope.weekNumber = firstDayNextWeek.isoWeek();
                        loadCurrentWeek();
                        publishWeekChanged();
                    }
                    $scope.previousWeek = function () {
                        var previousWeek = getPreviousWeek();
                        $scope.weekYear = previousWeek.weekYear;
                        $scope.weekNumber = previousWeek.weekNumber;
                        loadCurrentWeek();
                        publishWeekChanged();
                    }

                    function getPreviousWeek() {
                        var lastDayPreviousWeek = moment($scope.week.startDate).subtract(1, 'days');
                        return {
                            weekYear: lastDayPreviousWeek.isoWeekYear(),
                            weekNumber: lastDayPreviousWeek.isoWeek(),
                            translatedWeekNumber: lastDayPreviousWeek.isoWeek()
                        }
                    }


                    var filteredColor = null;
                    $scope.shouldClearColorSelection = null;
                    $scope.filterColor = function (color) {
                        $scope.shouldClearUserSelection = {
                            status: true
                        };
                        resetFilter();
                        // Make sure previous filter user is not active anymore.
                        if (color) {
                            filterTagByColor(color.background);
                        } else {
                            angular.element("div .portlet").show();
                        }
                        filteredColor = color;
                    }

                    var filteredUser = null;
                    $scope.shouldClearUserSelection = null;
                    $scope.filterUser = function (user) {
                        $scope.shouldClearColorSelection = {
                            status: true
                        };
                        resetFilter();
                        // Make sure previous filter user is not active anymore.
                        if (user) {
                            filterTagByUser(user.id);
                        } else {
                            angular.element("div .portlet").show();
                        }
                        filteredUser = user;
                    }

                    function filterTagByColor(color) {
                        var taskToShow = getColorFilter(color);
                        angular.element("div .portlet").hide();
                        $(taskToShow).show();
                        var filter = {
                            color: color
                        };
                        updateActionlistOptions($scope.actionListOptions.targetHeight, filter);     
                    }

                    function filterTagByUser(userId) {
                        var taskToShow = getUserFilter(userId);
                        angular.element("div .portlet").hide();
                        $(taskToShow).show();
                        var filter = {
                            userId: userId
                        };
                        updateActionlistOptions($scope.actionListOptions.targetHeight, filter);     
                    }

                    $scope.userListExpanded = function () {

                    }

                    $scope.filterTags = function (tag) {
                        // find all id not filter;
                        $scope.tagFilterState = activeFilterButton;
                        filteredUser = null;
                       
                        $scope.shouldClearUserSelection = {
                            status: true
                        };
                        $scope.shouldClearColorSelection = {
                            status: true
                        };
                        tag.isActive = !tag.isActive;
                        var result = getFilter();
                        if (result.activeTags === 0) {
                            $scope.clearFilter();
                            return;
                        }
                        angular.element("div .portlet").hide();
                        for (var i = 0; i < result.tasksToShow.length; i++) { // show only selected
                            angular.element("div .portlet[data-id='" + result.tasksToShow[i] + "']").show();
                        }
                        var filteredTags = $filter('filter')($scope.tags, { isActive: true }, true);
                        var filter = {
                            tags: filteredTags
                        };
                        updateActionlistOptions($scope.actionListOptions.targetHeight, filter);
                    }
                    $scope.showTaskWithoutTag = function () {
                        resetFilter();
                        angular.element("div .portlet").hide();
                        var allTasks = $scope.tasks;
                        for (var i = 0; i < allTasks.length; i++) { // Hide all 
                            if (allTasks[i].tags.length == 0) {
                                angular.element("div .portlet[data-id='" + allTasks[i].id + "']").show();
                            };
                        }
                    }
                    $scope.clearFilter = function () {   
                        $scope.shouldClearUserSelection = {
                            status: true
                        };
                        $scope.shouldClearColorSelection = {
                            status: true
                        };  
                        resetFilter();
                        angular.element("div .portlet").show();
                    }

                   
                  
                    function resetFilter() {
                        $scope.tagFilterState = inActiveFilterButton;
                        $scope.isUnprocessedFilterActive = false;
                   
                        updateActionlistOptions($scope.actionListOptions.targetHeight, {});

                        if($scope.tags){
                            var tags = $scope.tags;
                            for (var i = 0; i < tags.length; i++) { // Hide all 
                                tags[i].isActive = false;
                            }
                        }

                        $(".rowcategory").css("height", 100); // default height, so rows are not too long when tasks are filtered away
                    }

                    function applyFilter(tags) {
                        for (var i = 0; i < tags.length; i++) { // Hide all 
                            tags[i].isActive = false;
                        }
                    }
                    

                    function getColorFilter(color) {
                        var tasks = angular.element("div .portlet[data-color='" + color + "']");
                        return tasks;
                    }
                    function getUserFilter(userId) {
                        var tasks = angular.element("div .portlet[data-responsibleuserid='" + userId + "']");
                        return tasks;
                    }

                    function getFilter() {
                        // find all active filters
                        var tasksToShow = [];
                        var tags = $scope.boardData.tags;
                        var activeTags = 0;
                        for (var i = 0; i < tags.length; i++) {
                            if (tags[i].isActive == true) {
                                tasksToShow = tasksToShow.concat(tags[i].tasks);
                                activeTags += 1;
                            }
                        }
                        return {
                            activeTags: activeTags,
                            tasksToShow: tasksToShow
                        };

                    }


                    function subscribeToEvents() {
                        $scope.subscribe("TaskUpdated", function (data) { reloadTags() });
                        $scope.subscribe("NewTask", function (data) {
                            reloadTags();
                        });
                        $scope.subscribe("TaskDeleted", function (data) { reloadTags() });
                        $scope.subscribe("TaskArchived", function (data) { reloadTags() });
                        $scope.subscribe("TaskMovedFromArchive", function (data) { reloadTags() });
                    }
                    subscribeToEvents();



                    function reloadTags() {
                        console.log("reloading tags");
                        if ($scope.isWeeklyBoard) {
                            boardService.getTagsForWeek($scope.boardid, { from: $scope.week.startDate, to: $scope.week.endDate }).then(function (tags) {
                                $scope.boardData.tags = tags;
                                $scope.tags = tags;
                            });
                        } else {
                            boardService.getTags($scope.boardid).then(function (tags) {
                                $scope.boardData.tags = tags;
                                $scope.tags = tags;
                            });
                        }

                    }

                    $scope.addToFavorites = function () {
                        $scope.isStar = true;
                        var board = {
                            id: $scope.boardid,
                            name: $scope.title,
                            boardType: $scope.boardData.board.boardType
                        };
                        $scope.quickAccess.push(board);
                        saveQuickAccess();
                    }
                    $scope.removeFromFavorites = function () {
                        $scope.isStar = false;
                        var isFavorite = $filter('filter')($scope.quickAccess, { id: $scope.boardid })
                        if (isFavorite.length > 0) {
                            var currentIndex = $scope.quickAccess.indexOf(isFavorite[0]);
                            $scope.quickAccess.splice(currentIndex, 1);
                            saveQuickAccess();
                        }
                    }
                    function saveQuickAccess() {
                        employeeService.updateUserPreference("FlexiboardFavoriteBoards", $scope.quickAccess).then(function (settings) {
                            $scope.publish("FlexiboardFavoriteBoardsChanged", $scope.quickAccess);
                        });
                    }

                    function checkFavorite(){
                        employeeService.getUserPreference("FlexiboardFavoriteBoards").then(function (settings) {
                            if (settings && settings.value) {
                                angular.forEach(settings.value, function (board) {
                                    if (board) {
                                        if(board.id == $scope.boardid){
                                            $scope.isStar = true;
                                        }
                                        $scope.quickAccess.push(board);
                                    }
                                });
                            }
                        });
                    }
                }
            }
        }]);




