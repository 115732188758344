import * as staticFileService from "@common/shared/staticFileService"
import * as timeService from "@common/services/timeService"

var DigiLean = angular.module('DigiLean');
DigiLean
    .controller('personalTaskListController', ['$scope', '$document', '$filter', '$uibModal', 'boardTaskService', 'authService', 'deviationService', 'suggestionService', 'navigationService', 'strategyService',
        function ($scope, $document, $filter, $uibModal, boardTaskService, authService, deviationService, suggestionService, navigationService, strategyService) {
            var colorClasses = ["label-primary", "label-warning-light"];
            var userId = authService.getCurrentUser().userId;
            $scope.hasStrategy = true; //navigationService().hasModule("STRATEGY");

            $scope.static = staticFileService;
            $scope.subscribe('UserAuthenticatedAndReady', function (profile) {
                userId = profile.user.id;
                $scope.hasStrategy = navigationService().hasModule("STRATEGY");
            });
            $scope.workList = {};

            $scope.isInboxZero = false;

            $scope.createNew = function () {
                var today = moment().startOf('day');
                var modalInstance = $uibModal.open({
                    backdrop: 'static',
                    animation: true,
                    templateUrl: 'newtasktopersonaltasklist.html',
                    controller: 'newtasktopersonaltasklistController',
                    resolve: {
                        options: function () {
                            var options = {
                                date: today
                            }
                            return options;
                        }
                    }
                });
            }

            $scope.openA3ActionItem = function (id) {
                boardTaskService().get(id).then(function (task) {
                    var showstarttime = false;
                    if(task.startTime){
                        showstarttime = true;
                    }
                    var modalInstance = $uibModal.open({
                        backdrop: 'static',
                        animation: true,
                        templateUrl: 'boardTask.html',
                        controller: 'boardTaskController',
                        resolve: {
                            task: function () {
                                return task;
                            },
                            activeTab: function () {
                                return "Info";
                            },
                            options: function () {
                                var options = {
                                    archiveAvailable: false,
                                    canChangeBoard: false,
                                    startdateAvailable: showstarttime
                                }
                                return options;
                            }
                        }
                    });
                });
            }

            $scope.openForEdit = function (id) {
                boardTaskService().get(id).then(function (task) {
                    var showstarttime = false;
                    if(task.startTime){
                        showstarttime = true;
                    }
                    var modalInstance = $uibModal.open({
                        backdrop: 'static',
                        animation: true,
                        templateUrl: 'boardTask.html',
                        controller: 'boardTaskController',
                        resolve: {
                            task: function () {
                                return task;
                            },
                            activeTab: function () {
                                return "Info";
                            },
                            options: function () {
                                var options = {
                                    archiveAvailable: false,
                                    canChangeBoard: true,
                                    startdateAvailable: showstarttime
                                }
                                return options;
                            }
                        }
                    });
                });
            }

            $scope.openImprovementSuggestion = function (id) {
                suggestionService().get(id).then(function (suggestion) {
                    var modalInstance = $uibModal.open({
                        backdrop: 'static',
                        templateUrl: 'fullImprovementInfoForm.html',
                        controller: 'fullImprovementModalController',
                        windowClass: 'fullSuggestion-modal-window',
                        resolve: {
                            suggestion: function () {
                                return suggestion;
                            },
                            activeTab: function () {
                                return "Info";
                            }
                        }
                    });
                });
            }

            $scope.openDeviation = function (id) {
                deviationService().get(id).then(function (deviation) {
                    var modalInstance = $uibModal.open({
                        backdrop: 'static',
                        templateUrl: 'fullDeviation.html',
                        controller: 'fullDeviationController',
                        windowClass: 'full-screen',
                        resolve: {
                            deviation: function () {
                                return deviation;
                            },
                            activeTab: function () {
                                return "Info";
                            }
                        }
                    });
                });
            }

            $scope.threeCStatusChangedHandler = function (status) {
                var itemStatus = {
                    boardTaskId: task.id,
                    status: status.toString()
                }
                threeCService().updateStatus(item.id, itemStatus).then(function (data) {
                });
            }
            // Subscribe to Events
            var subscribeToEvents = function () {
                // DEVIATION Events
                $scope.subscribe('DeviationChangedStatus', function (status) {
                    updateTaskStatus(status.deviationId, status.status, "Deviation");
                });
                $scope.subscribe('DeviationResponsibleChanged', function (responsibleChanged) {
                    if (responsibleChanged.oldResponsibleUserId === userId || responsibleChanged.newResponsibleUserId === userId) {
                        getActionList();
                    }
                });
                $scope.subscribe('DeviationUpdated', function (deviation) {
                    if (deviation.ownerUserId === userId) {
                        getActionList();
                    }
                });
                $scope.subscribe('DeviationDeleted', function (deviation) {
                    var id = deviation.id;
                    var isTaskIdInList = isTaskInWorkList(id);
                    if (isTaskIdInList) {
                        getActionList();
                    }
                });
                // Improvement events
                // 
                $scope.subscribe('ImprovementChangedStatus', function (status) {
                    updateTaskStatus(status.suggestionId, status.status, "Improvement");
                });
                $scope.subscribe('ImprovementResponsibleChanged', function (responsibleChanged) {
                    if (responsibleChanged.oldResponsibleUserId === userId || responsibleChanged.newResponsibleUserId === userId) {
                        getActionList();
                    }
                });
                $scope.subscribe('ImprovementUpdated', function (improvement) {
                    if (improvement.responsibleUserId === userId || improvement.responsibleUserId === userId) {
                        getActionList();
                    }
                });
                $scope.subscribe('ImprovementDeleted', function (improvement) {
                    var id = improvement.id;
                    var isTaskIdInList = isTaskInWorkList(id);
                    if (isTaskIdInList) {
                        getActionList();
                    }
                });
                // Task events
                
                $scope.subscribe("TaskDurationUpdated", function (data) {
                    var isTaskIdInList = isTaskInWorkList(data.taskId);
                    if (isTaskIdInList) {
                        getActionList();
                    }
                });
                $scope.subscribe('TaskStatusUpdated', function (status) {
                    updateTaskStatus(status.taskId, status.status, "BoardTask");
                });
                $scope.subscribe('TaskMoved', function (taskMoved) {
                    if (taskMoved.responsibleUserId === userId) {
                        getActionList();
                    }
                });
                $scope.subscribe('TaskStartDateUpdated', function (taskMoved) {
                    var isTaskIdInList = isTaskInWorkList(taskMoved.taskId);
                    if (isTaskIdInList) {
                        getActionList();
                    }
                });
                $scope.subscribe('TaskDateMoved', function (taskMoved) {
                    if (taskMoved.responsibleUserId === userId) {
                        getActionList();
                    }
                });

                $scope.subscribe('TaskUpdated', function (task) {
                    if (task.responsibleUserId === userId) {
                        getActionList();
                    }
                });
                $scope.subscribe('TaskAssigned', function (taskAssigned) {
                    if (taskAssigned.userId === userId || taskAssigned.previousUserId === userId) {
                        getActionList();
                    } else {
                        var isTaskIdInList = isTaskInWorkList(taskAssigned.taskId);
                        if (isTaskIdInList) {
                            getActionList();
                        }
                    }
                });
                $scope.subscribe('NewTask', function (task) {
                    // If current user is involved in task.
                    if (!task.responsibleUserId) return;
                    if (task.responsibleUserId === userId) {
                        getActionList();
                    }
                });
                $scope.subscribe('CreateActionListItem', function (task) {
                    // If current user is involved in task.
                    if (!task.responsibleUserId) return;
                    if (task.responsibleUserId === userId) {
                        getActionList();
                    }
                });
                $scope.subscribe('TaskDeleted', function (id) {
                    // If current user is involved in task.
                    var isTaskIdInList = isTaskInWorkList(id);
                    if (isTaskIdInList) {
                        getActionList();
                    }
                });
                $scope.subscribe('TaskArchived', function (id) {
                    // If current user is involved in task.
                    var isTaskIdInList = isTaskInWorkList(id);
                    if (isTaskIdInList) {
                        getActionList();
                    }
                });
                $scope.subscribe('RepeatingTasksDeleted', function (taskIds) {
                    // If current user is involved in task.
                    angular.forEach(taskIds, function (id, key) {
                        var isTaskIdInList = isTaskInWorkList(id);
                        if (isTaskIdInList) {
                            getActionList();
                        }
                    });

                });
            }
            subscribeToEvents();

            var checkInboxStatus = function () {
                var status = true;
                if ($scope.workList.overdue.length > 0) {
                    var okCount = $filter('filter')($scope.workList.overdue, { status: "OK" }, true);
                    if (okCount.length != $scope.workList.overdue.length) {
                        status = false;
                    }
                }
                if ($scope.workList.today.length > 0) {
                    //All tasks needs to be completed to show inbox zero
                    var okCount = $filter('filter')($scope.workList.today, { status: "OK" }, true);
                    if (okCount.length != $scope.workList.today.length) {
                        status = false;
                    }
                }
                $scope.isInboxZero = status;
            }

            var isTaskInWorkList = function (id) {
                var workList = $scope.workList;
                var allTask = workList.overdue.concat(workList.today.concat(workList.future));
                var task = $filter('filter')(allTask, { id: id });

                if (task.length > 0) {
                    return true;
                }
                return false;
            };
            function setStrategyGoalInfo(task, strategytasks) {
                var matchingTasks = $filter('filter')(strategytasks, { id: task.id }, true);
                if (matchingTasks.length > 0) {
                    task.taskType = "Strategy";
                    task.goalcolor = matchingTasks[0].color;
                    task.goalname = matchingTasks[0].strategyGoal;
                    task.assetId = matchingTasks[0].assetId;
                    task.breakthroughGoalId = matchingTasks[0].breakthroughGoalId;
                }
            }

            var initTask = function (task) {

            }
            // Get tasks for current user
            var getActionList = function () {
                $scope.isLoading = true;
                if ($scope.hasStrategy) {

                    var timeperiod = timeService.getTimePeriodBeforeAfter('180', '30');
                    strategyService().getMyStrategyTasks(timeperiod).then(function (strategytasks) {
                        boardTaskService().getListForCurrentUser().then(function (data) {
                            // assign color classes
                            var currentColorIndex = 0;
                            var currentActionList = "";
                            data.overdue.forEach(function (element, index, array) {
                                if (currentActionList === "") {
                                    currentActionList = element.boardName;
                                }
                                if (currentActionList !== element.boardName) {
                                    currentActionList = element.boardName;
                                    if ((currentColorIndex + 1) < colorClasses.length) {
                                        currentColorIndex += 1;
                                    } else {
                                        currentColorIndex = 0;
                                    }
                                }
                                setStrategyGoalInfo(element, strategytasks);
                                element.colorClass = colorClasses[currentColorIndex];
                            });
                            data.today.forEach(function (element, index, array) {
                                if (currentActionList === "") {
                                    currentActionList = element.boardName;
                                }
                                if (currentActionList !== element.boardName) {
                                    currentActionList = element.boardName;
                                    if ((currentColorIndex + 1) < colorClasses.length) {
                                        currentColorIndex += 1;
                                    } else {
                                        currentColorIndex = 0;
                                    }
                                }
                                element.colorClass = colorClasses[currentColorIndex];
                                setStrategyGoalInfo(element, strategytasks);
                            });
                            data.future.forEach(function (element, index, array) {
                                if (currentActionList === "") {
                                    currentActionList = element.boardName;
                                }
                                if (currentActionList !== element.boardName) {
                                    currentActionList = element.boardName;
                                    if ((currentColorIndex + 1) < colorClasses.length) {
                                        currentColorIndex += 1;
                                    } else {
                                        currentColorIndex = 0;
                                    }
                                }
                                element.colorClass = colorClasses[currentColorIndex];
                                setStrategyGoalInfo(element, strategytasks);
                            });

                            $scope.workList = data;

                            checkInboxStatus();
                            $scope.isLoading = false;
                        });

                    });
                } else {
                    boardTaskService().getListForCurrentUser().then(function (data) {
                        // assign color classes
                        var currentColorIndex = 0;
                        var currentActionList = "";
                        data.overdue.forEach(function (element, index, array) {
                            if (currentActionList === "") {
                                currentActionList = element.boardName;
                            }
                            if (currentActionList !== element.boardName) {
                                currentActionList = element.boardName;
                                if ((currentColorIndex + 1) < colorClasses.length) {
                                    currentColorIndex += 1;
                                } else {
                                    currentColorIndex = 0;
                                }
                            }
                            element.colorClass = colorClasses[currentColorIndex];
                            initTask(element);
                        });
                        data.today.forEach(function (element, index, array) {
                            if (currentActionList === "") {
                                currentActionList = element.boardName;
                            }
                            if (currentActionList !== element.boardName) {
                                currentActionList = element.boardName;
                                if ((currentColorIndex + 1) < colorClasses.length) {
                                    currentColorIndex += 1;
                                } else {
                                    currentColorIndex = 0;
                                }
                            }
                            element.colorClass = colorClasses[currentColorIndex];
                            initTask(element);
                        });
                        data.future.forEach(function (element, index, array) {
                            if (currentActionList === "") {
                                currentActionList = element.boardName;
                            }
                            if (currentActionList !== element.boardName) {
                                currentActionList = element.boardName;
                                if ((currentColorIndex + 1) < colorClasses.length) {
                                    currentColorIndex += 1;
                                } else {
                                    currentColorIndex = 0;
                                }
                            }
                            element.colorClass = colorClasses[currentColorIndex];
                            initTask(element);
                        });

                        $scope.workList = data;

                        checkInboxStatus();
                        $scope.isLoading = false;
                    });

                }
            }
            getActionList();

            function updateTaskStatus(id, status, entityType) {
                if (!entityType) entityType = "BoardTask";
                var workList = $scope.workList;
                var allTask = workList.overdue.concat(workList.today.concat(workList.future));
                var task = $filter('filter')(allTask, { id: id });

                if (task.length > 0) {
                    // if (task[0].entityType === entityType) {
                    //     task[0].status = status;
                    // }
                    checkInboxStatus();
                }
            }

        }]);

